
.navbar {
  @apply flex w-full p-1  justify-between items-center  bg-white shadow-xl rounded-es-xl rounded-ee-xl;
}

.logo-container{
  @apply flex items-center gap-5
}

.logo {
    @apply w-[155px] h-[80px]
}

.logo img{
    @apply w-full h-full
}

.navlinks{
  @apply w-full 2xl:w-fit 2xl:gap-10 h-full  justify-around items-center font-bold text-[17px] px-5 hidden lg:flex
}

.hover-transition{
    @apply hover:text-primary transition duration-300
}
  
.navlink-class{
  @apply text-non_selected flex flex-col justify-center items-center gap-1 hover-transition
}

a.active {
  @apply text-primary;
}

.active-dot{
  @apply w-1 h-1 rounded-full 
}

.active-line{
  @apply w-full h-0.5  
}

a.active .active-dot{
  @apply bg-primary
}

a.active .active-line{
  @apply bg-primary
}

.user-profile-container{
  @apply w-[50px] h-[50px] p-1 cursor-pointer
}

.user-profile{
  @apply  rounded-full overflow-hidden  border border-primary shadow-sm shadow-red-700
}

.earnings-container{
  @apply flex  justify-center items-center gap-5 px-4
}

